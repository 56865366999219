import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
	<div className="position-relative 404-page">
		<Layout footerBottom={true}>
			<SEO title="404 not found" />
			<div className="page-headline text-center">
				<div className="container">
					<div className="section-heading my-5">
						<h1>404 not found!!</h1>
					</div>
				</div>
			</div>
		</Layout>
	</div>
)

export default NotFoundPage
